import React, { useState } from "react";
import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

// Components
import Form from "./components/Form/Form";
import LogosQuestionnaire from "./components/LogosQuestionnaire/LogosQuestionnaire";
import Signature from "./components/Signature/Signature";
import AppBarLogo from "./components/AppBarLogo/AppBarLogo";
import NotificatorModal from "./components/NotificatorModal/NotificatorModal";
import UnloggedView from "./components/UnloggedView/UnloggedView";

import styles from "./App.styles";

const initialState = {
  fullName: "",
  position: "",
  skype: "",
  phone: "",
  email: "",
  location: "",
  copied: false,
  placeholders: {
    fullName: "Alberto Pardo - Banano",
    position: "CEO & Founder, Adsmovil",
    skype: "banano2000",
    phone: "+57 (310) 224 2624",
    email: "banano@adsmovil.com",
    location: "Bogotá, Colombia",
  },
};

function App() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    picture: "",
  });

  const [logos, setLogos] = useState({
    primary: {
      isOOH: false,
      isNuestraTV: false,
      isNewLogo: false,
      isLastLogo: true,
    },
    isSecondaryLogo: false,
    secondary: {
      isAmazonLogo: true,
      isNMSDC: false,
      isDigitalTurbine: false,
      isRappiAmplify: false,
    },
  });

  const [state, setState] = React.useState(initialState);

  const [haveSkype, setHaveSkype] = useState(false);
  const [actionModal, setActionModal] = useState("copy"); // copy, noAdsmovil, noUser
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    copyElementContents("mySignature");
    setActionModal("copy");
    setOpenModal(true);
  };

  function copyElementContents(elementId) {
    let copyText = document.querySelector(".signature");
    const range = document.createRange();
    if (copyText) {
      range.selectNode(copyText);
    }
    const windowSelection = window.getSelection();
    if (windowSelection) {
      windowSelection.removeAllRanges();
      windowSelection.addRange(range);
    }
    try {
      let successful = document.execCommand("copy");
      console.log(successful ? "Success" : "Fail");
      setState((prevState) => ({
        ...prevState,
        copied: true,
      }));
    } catch (err) {
      console.log("Fail");
    }
  }

  const handleChangeSkype = (event) => {
    event.target.value === "" ? setHaveSkype(false) : setHaveSkype(true);
  };

  return (
    <>
      <AppBarLogo
        isLogged={isLogged}
        setIsLogged={setIsLogged}
        user={user}
        setUser={setUser}
        setOpenModal={setOpenModal}
        setActionModal={setActionModal}
      />
      <Grid
        container
        sx={
          isLogged
            ? styles.appContainer
            : { ...styles.appContainer, padding: "none" }
        }
        direction={isTablet ? "column" : "row"}
      >
        {isLogged ? (
          <>
            <Form
              state={state}
              setState={setState}
              handleChangeSkype={handleChangeSkype}
              user={user}
            />

            <Grid
              item
              container
              xs={6}
              id="mySignature"
              sx={{ marginX: "auto" }}
              direction="column"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sx={styles.questionnaireContainer}>
                <LogosQuestionnaire logos={logos} setLogos={setLogos} />
              </Grid>
              <Grid item sx={styles.signatureContainer}>
                <Signature
                  fullName={state.fullName}
                  position={state.position}
                  skype={state.skype}
                  phone={state.phone}
                  email={state.email}
                  location={state.location}
                  facebook={state.facebook}
                  photo={state.photo}
                  haveSkype={haveSkype}
                  logos={logos}
                  setLogos={setLogos}
                />
              </Grid>
              <Box>
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  onClick={handleClick}
                  style={{ marginTop: "2rem" }}
                >
                  COPY
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <UnloggedView
            setActionModal={setActionModal}
            setIsLogged={setIsLogged}
            setOpenModal={setOpenModal}
            setUser={setUser}
          />
        )}
      </Grid>
      <NotificatorModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        actionModal={actionModal}
      />
    </>
  );
}

export default App;
