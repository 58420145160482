import React, { useState } from "react";
import { Grid, Typography, IconButton, Switch } from "@mui/material";

import styles from "./LogosQuestionnaire.styles";

const LogosQuestionnaire = ({ logos, setLogos }) => {
  const [logosUrl] = useState({
    principal: [
      {
        key: "isLastLogo",
        url: "https://storage.googleapis.com/adsmovil-services-bucket/firmaNueva-logo.png",
      },
      {
        key: "isOOH",
        url: "https://storage.googleapis.com/adsmovil-services-bucket/logo_ooh_azul.png",
      },
      {
        key: "isNuestraTV",
        url: "https://storage.googleapis.com/adsmovil-services-bucket/NuestraTV.logo.png",
      },
      {
        key: "isNewLogo",
        url: "https://storage.googleapis.com/adsmovil-services-bucket/new_adsmovil.png",
      },
    ],
    secondary: [
      {
        key: "isAmazonLogo",
        url: "https://storage.googleapis.com/adsmovil-services-bucket/amazon_logo.png",
      },
      {
        key: "isNMSDC",
        url: "https://storage.googleapis.com/adsmovil-services-bucket/logo-certified-us.png",
      },
      {
        key: "isDigitalTurbine",
        url: "https://storage.googleapis.com/adsmovil-services-bucket/digital_turbine_logo.png",
      },
      {
        key: "isRappiAmplify",
        url: "https://storage.googleapis.com/adsmovil-services-bucket/rappi_amplify.png",
      },
    ],
  });

  const chageCheckedSecondary = () => {
    setLogos({ ...logos, isSecondaryLogo: !logos.isSecondaryLogo });
  };

  const changesLogos = (type, icon) => {
    setLogos((prevLogos) => {
      const updatedLogos = {
        ...prevLogos,
        [type]: Object.keys(prevLogos[type]).reduce((acc, key) => {
          acc[key] = key === icon;
          return acc;
        }, {}),
      };
      return updatedLogos;
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h4" sx={styles.questionnaireTitle}>
          ¿Qué logo principal desea?
        </Typography>

        <Grid item container direction="row" sx={{ marginBottom: "2rem" }}>
          {logosUrl.principal.map(({ key, url }) => (
            <Grid item key={key}>
              <IconButton
                sx={styles.logosContainer}
                onClick={() => changesLogos("primary", key)}
              >
                <img
                  src={url}
                  width={
                    key === "isNewLogo" || key === "isLastLogo"
                      ? "120px"
                      : "150px"
                  }
                  alt={`Logo de ${key}`}
                />
              </IconButton>
            </Grid>
          ))}
        </Grid>
        <Grid item container>
          <Typography variant="h4" sx={styles.questionnaireTitle}>
            ¿Desea algún logo secundario?
          </Typography>
          <Switch
            color="secondary"
            inputProps={{ "aria-label": "controlled" }}
            checked={logos.isSecondaryLogo}
            onClick={chageCheckedSecondary}
          />
        </Grid>
        <Grid item container direction="row">
          {logosUrl.secondary.map(({ key, url }) => (
            <Grid item key={key}>
              <IconButton
                sx={{
                  ...styles.logosContainer,
                  filter: logos.isSecondaryLogo
                    ? "grayscale(0%)"
                    : "grayscale(100%)",
                }}
                disabled={!logos.isSecondaryLogo}
                onClick={() => changesLogos("secondary", key)}
              >
                <img
                  src={url}
                  width={key === "isNMSDC" ? "50px" : "100px"}
                  alt={`Logo ${key}`}
                />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LogosQuestionnaire;
